@use '@/styles/tokens/spacing' as *;
@use '@/styles/mixins/media-queries' as *;

.plans {
  &__customButton {
    margin: $spacing-xxs 0;
  }

  &__actions {
    & > button {
      @include maxScreenSize(desktop) {
        &:first-of-type {
          margin-bottom: $spacing-lg;
        }

        &:last-of-type {
          position: fixed;
          bottom: $spacing-xxs;
          left: $spacing-xxxs;
          width: calc(100% - $spacing-xs);
        }
      }
    }
  }
}
