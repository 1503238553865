@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.highlightTitle {
  display: flex;
  align-items: center;

  &--xs {
    @include typography($font-size-xs, $line-height-nano);
    gap: $spacing-nano;
  }

  &--small {
    @include typography($font-size-sm, $line-height-sm);
    gap: $spacing-nano;
  }

  &--medium {
    @include typography($font-size-md, $line-height-md);
    gap: $spacing-nano;
  }

  &--large {
    @include typography($font-size-lg, $line-height-lg);
    gap: $spacing-xxxs;
  }

  a {
    color: $color-blue-primary;
  }
}
