@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plan {
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: $spacing-xxxs 0 $spacing-nano;

  @include minScreenSize(desktop) {
    padding: 0;
    border-radius: $border-radius-sm;
    border: $border-width-hairline solid $color-neutral-light;
  }

  &__priceInfo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include maxScreenSize(desktop) {
      gap: $spacing-xs;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: $spacing-quarck;

    @include minScreenSize(desktop) {
      padding: $spacing-xxxs;
    }
  }

  &__label {
    @include typography($font-size-sm, $line-height-xxs, $font-weight-medium);
    white-space: nowrap;
  }

  &__price {
    @include typography($font-size-xl, $line-height-xxl, $font-weight-bold);
  }

  &__cta {
    margin-top: $spacing-nano;

    @include maxScreenSize(desktop) {
      display: none;
    }
  }
}
