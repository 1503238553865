@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.additionalServices {
  &__checkbox {
    display: flex;
    gap: $spacing-micro;

    @include minScreenSize(desktop) {
      align-items: center;
    }

    & > label > p {
      @include typography($font-size-md, $line-height-md, $font-weight-medium);
    }
  }
}
