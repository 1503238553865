@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plans {
  flex-flow: wrap;
  margin-top: $spacing-nano;

  @include minScreenSize(desktop) {
    display: flex;
    gap: $spacing-xxs;
    margin-top: $spacing-sm;
  }

  &__item {
    flex: 1;
    display: flex;
    // min-width: fit-content;
    gap: $spacing-quarck;

    // @include minScreenSize(desktop) {
    //   max-width: 50%;
    //   margin: 0 auto;
    // }

    @include maxScreenSize(desktop) {
      &:not(:last-of-type) {
        border-bottom: $border-width-hairline solid $color-neutral-light;
      }
    }
  }

  &__input {
    margin: auto;

    @include minScreenSize(desktop) {
      display: none;
    }
  }

  &__plan {
    flex: 1;
  }
}
