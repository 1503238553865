@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.customPlan {
  flex: 1;
  padding: 0;
  border-bottom: $border-width-hairline solid $color-neutral-light;

  @include minScreenSize(desktop) {
    border-radius: $border-radius-sm;
    border: $border-width-hairline solid $color-neutral-light;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing-xxxs;

    @include maxScreenSize(desktop) {
      gap: $spacing-nano;
    }
  }

  &__text {
    @include typography($font-size-xs, $line-height-xxs);
  }
}
