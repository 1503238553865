@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.softlead {
  display: flex;
  flex-direction: column;

  &__plan,
  &__additionalServices {
    margin-bottom: $spacing-xxs;
  }

  &__plan {
    @include maxScreenSize(desktop) {
      padding: $spacing-xxxs $spacing-xxxs $spacing-micro;
      border-radius: $border-radius-sm;
      border: $border-width-hairline solid $color-neutral-light;
    }
  }
}
