@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.unitValues {
  &__table {
    @include typography($font-size-xxs, $line-height-nano);
    border-spacing: 0 $spacing-nano;
    td:last-of-type {
      white-space: nowrap;
    }

    th:first-of-type,
    td:first-of-type {
      text-align: left;
      text-transform: capitalize;
    }

    th:last-of-type,
    td:last-of-type {
      text-align: right;
    }
  }
}
