@use '@/styles/tokens' as *;
@use '@/styles/mixins/typography' as *;

.badge {
  @include typography($font-size-xxs, $line-height-nano, $font-weight-medium);

  width: fit-content;
  display: flex;
  align-items: center;
  gap: $spacing-quarck;
  padding: $spacing-quarck $spacing-nano;
  border-radius: $border-radius-sm;

  &--round {
    border-radius: $border-radius-xl;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--red {
    color: $color-neutral-brightest;
    background-color: $color-red-primary;
  }

  &--green {
    color: $color-green-primary;
    background-color: $color-green-bright;
  }
}
