@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.confirmationReview {
  &__plan {
    padding-top: $spacing-nano;

    @include maxScreenSize(desktop) {
      padding: $spacing-xxxs $spacing-xxxs $spacing-micro;
      border-radius: $border-radius-sm;
      border: $border-width-hairline solid $color-neutral-light;
    }
  }
}
