@use '@/styles/tokens' as *;
@use '@/styles/mixins' as *;

.plans {
  padding: $spacing-xs 0 $spacing-xxxs;
  background-color: $color-neutral-bright;

  @include minScreenSize(desktop) {
    padding: $spacing-xs 0;
  }

  &__title {
    margin-top: $spacing-xxs;
  }

  &__subtitle {
    margin-top: $spacing-xxxs;
  }

  &__loader {
    width: 72px;
    height: 72px;
    margin: $spacing-xxxs auto;
    border-width: $border-width-thick;
    border-color: $color-neutral-light;
    border-left-color: $color-neutral-lightest;
  }

  &__button {
    margin-top: $spacing-xxs;

    @include minScreenSize(desktop) {
      width: fit-content;
      margin: $spacing-xxs auto 0;
    }

    &--fixed {
      position: sticky;
      left: 0;
      bottom: $spacing-xxl;

      @include minScreenSize(desktop) {
        display: none;
      }
    }
  }

  &__disclaimer {
    @include typography($font-size-xs, $line-height-sm);

    text-align: center;
    margin-top: $spacing-xxs;
  }
}
